export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранение..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ID"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Сегодня"])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Вчера"])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешения разговаривать в этом канале."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соединения"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить сообщение"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите сообщение"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад вниз"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые сообщения"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сжать файл"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть профиль"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить роли"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кикнуть"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банить"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать user:tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цитировать"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как прочитанное"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки сервера"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти из сервера"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить канал"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить канал"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление эмодзи"])},
      "call-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты вызова"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры запуска"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программный статус"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ботами"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерфейс"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аккаунт"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список изменений"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIP функции"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать профиль"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переподключить Google Диск"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связать Google Диск"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эту информацию видят все."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените настройки уведомлений."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звук уведомления"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Издает классный звук, чтобы привлечь ваше внимание"])},
      "notification-mentions-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только упоминания/прямые сообщения"])},
      "notification-mentions-only-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делает так, что только упоминания и прямые сообщения будут воспроизводиться и отправляться."])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить уведомление"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте push-уведомления, даже когда приложение закрыто."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите свои собственные красивые эмоджи бесплатно! \nРазмер эмодзи не должен превышать 3МБ."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемые типы файлов: png, jpg, gif."])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить эмоджи"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки при запуске"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы изменить эти настройки, необходимо загрузить настольное приложение Nertivia."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть Nertivia при запуске"])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделитесь программами или играми, которые вы запускаете, добавив приложения ниже. \nЭто заменит ваш пользовательский статус при открытии программы и вернется обратно, когда программа будет закрыта."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы использовать функцию активности программы, вы должны загрузить настольное приложение Nertivia."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавайте пользователей-ботов, которые позволят вам использовать наш API для создания собственных причудливых ботов."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте команды бота, чтобы пользователи могли легко узнать, какие команды существуют для вашего бота."])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать бота"])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить команду"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать бота"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать команды"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пригласительную ссылку"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать URL-адрес приглашения"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление токеном"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать токен"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать токен"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить токен"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить бота"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы собираетесь удалить свой аккаунт"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что будет удалено"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что не будет удалено"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши сообщения, отправленные на общедоступные серверы"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требования"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить/удалить все серверы"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить всех ботов"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалите мой аккаунт"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После смены языка может потребоваться перезагрузка. \nВнесите свой вклад, отправив пулл-реквест в репозиторий GitHub."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводчики"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените внешний вид Нертивии."])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвета"])},
      "time-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат времени"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема по умолчанию"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Dark"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэллоуин 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предустановленные темы"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственный CSS"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие настройки"])},
      "navigation-bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель навигации"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включите незавершенные или экспериментальные функции."])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас недоступны WIP-функции."])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включает ВСЕ разрешения"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправления сообщения"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка сообщений на каналы сервера"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить или удалить роли"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление каналами"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновите или удалите каналы"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кик"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгнать пользователя"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бан"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать пользователя от присоеднинения к серверу"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление каналами"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление приглашениями"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость сервера"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление эмодзи"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление уведомлениями"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокированные пользователи"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить сервер"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями."])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль по умолчанию"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль бота"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую роль"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эту роль нельзя удалить/назначить другому пользователю, так как она была создана ботом. \nУдалите бота, чтобы удалить эту роль."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эту роль нельзя удалить или отменить назначение, так как это роль по умолчанию, которая применяется к каждому участнику."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите цвет роли"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название роли"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить роль"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление роли.."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спрятать роль"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие настройки сервера"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление каналами."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить канал"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление канала..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название канала"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это канал по умолчанию. \nВы можете изменить канал по умолчанию на странице Общие. \nКаналы по умолчанию не могут быть удалены."])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый канал"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлять сообщения"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение скорости (секунды)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текстовый канал"])},
      "category-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["канал HTML (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбанить пользователей здесь"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбанивание..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбанить"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только администраторы могут видеть все приглашения."])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать приглашения"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственное приглашение"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить собственную ссылку"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать приглашение"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создатель"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько раз была использована"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск пользователя"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя сервера для подтверждения"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить настройки уведомлений."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти настройки будут применяться только к вам и не повлияют на других."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить уведомления"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить звук"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить уведомления"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " пишет..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " и ", _interpolate(_list(1)), " пишут..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " и ", _interpolate(_list(2)), " пишут..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " человек пишут..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друзья"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавние"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраненные заметки"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить друга"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите, чтобы добавить собственный статус"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все догнали!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщения"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серверы"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дэшборд"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте друга, введя их имя пользователя и тег."])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Server"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь к серверу, введя код приглашения."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте свой собственный сервер. \nНачните с присвоения имени вашему серверу."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединиться"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавать"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офлайн"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На связи"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не доступны на данный момент"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заняты"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ищет, с кем поиграть"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Участники (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Офлайн (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие друзья"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медали"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединились"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокированы"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить друга"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять запрос"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить запрос"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить друга"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие серверы"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружается..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно обновление"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделить"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ушли из сервера"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["были кикнуты"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["были блокированы"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["присоединились к серверу!"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось подключиться к серверу."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим ограничения скорости"])}
  }
}